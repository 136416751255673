import React, { useEffect, useState } from 'react';
import styles from './style';

import { Typography, Box } from '@material-ui/core';

// import ImgProcess from '../../../images/datascientist/img_process.svg';
import ImgProcess from '../../../images/process.svg';
import Bg from '../../../images/element7.svg';
import datascientist from '../../../images/datascientist.svg';
// import ImgProcessMobile from '../../../images/datascientist/process_vertical.svg';
import ImgProcessMobile from '../../../images/process_mobile.svg';

import ScrollTo from '../../Common/ScrollTo';

import IconAws from '../../Common/Icons/IconAws';
import IconSmartpredict from '../../Common/Icons/IconSmartpredict';
import IconScikitlearn from '../../Common/Icons/IconScikitlearn';
import IconJupyter from '../../Common/Icons/IconJupyter';
import IconNltk from '../../Common/Icons/IconNltk';
import IconTensorFlow from '../../Common/Icons/IconTensorFlow';
import IconKeras from '../../Common/Icons/IconKeras';
import ListeExpertise from '../../PagesComponent/Home/ListeExpertise/ListeExpertise';


import Layout from '../../Common/Layout';
import classnames from 'classnames';
import ContactForm from '../../Common/ContactForm';
import BoxWithIcon from '../../Common/BoxWithIcon/BoxWithIcon';

import Techonologies from '../../Common/Technologies';

const TechnologiesItem2 = [
  {
    id: 1,
    icon: <IconAws width="85.223" height="50.96" viewBox="0 0 85.223 50.96" />,
    title: 'IconAws',
  },
  {
    id: 2,
    icon: (
      <IconSmartpredict
        width="248.276"
        height="50.961"
        viewBox="0 0 248.276 50.961"
      />
    ),
    title: 'IconSmartpredict',
  },
  {
    id: 3,
    icon: (
      <IconScikitlearn
        width="92.702"
        height="49.994"
        viewBox="0 0 92.702 49.994"
      />
    ),
    title: 'IconScikitlearn',
  },
  {
    id: 4,
    icon: (
      <IconJupyter
        width="191.634"
        height="50.961"
        viewBox="0 0 191.634 50.961"
      />
    ),
    title: 'IconJupyter',
  },
  {
    id: 5,
    icon: <IconNltk width="68.466" height="22.9" viewBox="0 0 68.466 22.9" />,
    title: 'IconNltk',
  },
  {
    id: 6,
    icon: <IconTensorFlow width="300" height="65.53" viewBox="0 0 300 65.53" />,
    title: 'IconTensorFlow',
  },
  {
    id: 7,
    icon: (
      <IconKeras width="167.414" height="50.961" viewBox="0 0 167.414 50.961" />
    ),
    title: 'IconKeras',
  },
];

const DataScientist = () => {
  const classes = styles();
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <Box id="datascientistScrollTop" className={classes.cardOfferSection}>
      {showScrollTo ? (
        <ScrollTo element="/datascientist#datascientistScrollTop" />
      ) : null}
      <Box
        display="flex"
        flexWrap="wrap"
        className={classes.externaliset}
        bgcolor="#0E0C38"
      >
        <Box className={classes.BgExtenalise} />
          <Layout className={classes.containerExternalise}>
            <Box className={classnames(classes.contentExternalise)}>
              <img src={datascientist} alt="Datascientist images" />
            </Box>
            <Box className={classnames(classes.titleWrapperExternalise)}>
              <Box>
                <Typography
                  variant="h2"
                  className={classnames('titreSection', classes.titleExternalise)}
                >
                  Externalisez <span>vos projets</span> <br/>Data science<span><br/> en mode régie </span>
                </Typography>
                {/* <Box className={classnames('titreSection', classes.titleExternalise)}>

                <Typography component='ul'>
                  <Typography component="li">Computer Vision </Typography>
                  <br/>
                  <Typography component="li">Iot </Typography>
                  <Typography component="li">Recommendations Systems</Typography>
                  <br/>

                  <Typography component="li">NLP </Typography>
                  <Typography component="li">Predictive maintenance </Typography>
                  <br/>

                  <Typography component="li">Business Intelligence (BI)/Data Viz </Typography>
                  <Typography component="li">Predictive maintenance </Typography>

                </Typography>
                </Box> */}
                {/* <Typography
                  className={classnames(
                    'textParagraphe',
                    classes.descriptionExternalise,
                  )}
                >
                  Focalisez-vous sur vos activités principales grâce à l'offshore.<br/> Prenez une longueur d'avance et gagnez du temps grâce à notre disponibilité à coût optimal. <br/>
                  Nos consultants vous accompagnent pour une consommation intelligente de vos données.<br/>
                  Ils élaborent pour vous des modèles prédictifs ou des dashboard avancés, et les intègrent dans vos processus métier. 
                </Typography> */}
              </Box>
            </Box>
          </Layout>
        <Box className={classes.etapesWrapper}>
          <Layout className={classes.servicesContainer}>
            <Box className={classes.etapesWrapperContainer}>
              <Box
                className={classes.etapesLeft}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="h3" className={classes.sectionEtapeTitle} >
                  <span>Nos expertises<br/>en Data science :</span>
                </Typography>

                <Typography
                  className={classnames(
                    'textParagraphe',
                    classes.sectionEtapeDescription,
                    classes.cardText,
                  )}
                >
                  {/* Prenez une longueur d’avance, avec une disponibilité rapide,
                  des couts raisonnables, évitez même les couts de recrutement.
                  <br />
                  <br />
                  Pour une exploitation intelligente de vos données. Nos Data
                  Scientist vous accompagnent dans la création de modèles
                  prédictifs avancés ainsi que leur application en solution
                  métiers pour permettre et favoriser vos prises de décisions
                  business. */}
                </Typography>
              </Box>
              <Box className={classes.etapesRight}>
                <Box
                  className={classes.services}
                  display="flex"
                  flexWrap="wrap"
                  alignItems="flex-start"
                >
                  <BoxWithIcon
                    // title="Vous nous envoyez vos attentes"
                    title="Computer Vision"
                    paragraph=""
                  />
                  <BoxWithIcon
                    title="Iot"                   
                    //  title="Nous nous convenons d'un premier échange"
                    paragraph=""
                  />
                  <BoxWithIcon
                    title="Recommendations Systems"                 
                      //  title="Nous vous proposons nos data scientist"
                    paragraph=""
                  />
                  <BoxWithIcon
                    title="NLP"                    
                    // title="Nous démarrons votre projet en mode régie"
                    paragraph=""
                  />
                     <BoxWithIcon
                    title="Predictive maintenance"                    
                    // title="Nous démarrons votre projet en mode régie"
                    paragraph=""
                  />
                     <BoxWithIcon
                    title="Business Intelligence (BI)/Data Viz"
                    paragraph=""
                  />
                     <BoxWithIcon
                    title="Predictive maintenance"
                    paragraph=""
                  />
                </Box>
              </Box>
            </Box>
          </Layout>
        </Box>
      </Box>
      <Box className={classes.process}>
        <Box className={classes.backgroundProcess}>
          <img src={Bg} alt="Bg images process" />
        </Box>
        <Layout className={classes.containerProcess}>
          <Box className={classes.titleProcess}>
            <Typography
              variant="h2"
              className={classnames('titreSection', classes.confiancesTitle)}
            >
              <span>Notre</span> processus
            </Typography>
          </Box>
          <Box className={classes.imgProcess}>
            <img src={ImgProcess} alt=" images Proces" />
          </Box>
          <Box className={classes.imgProcessMobile}>
            <img src={ImgProcessMobile} alt="images process mobiles" />
          </Box>
        </Layout>
      </Box>
      {/* <Techonologies
        bgcolor="#333333"
        title="Quelques unes<br/> <span>des</span> technologies <span>utilisées</span>"
        description="Les Langages, Frameworks ou Solutions que nous utilisons au quotidien"
        items={TechnologiesItem2}
        minWidthItem="120px"
        marginTopItem="40px"
        marginBottomItem="40px"
      /> */}
      <Box className={classnames('titresectionExpertise', classes.etapesWrapper)} bgcolor="#0E0C38">
          <Layout className={classes.servicesContainer}>
            <Box className={classes.etapesWrapperContainer}>
              <Box
                className={classes.etapesLeft}
                display="flex"
                flexDirection="column"  
                justifyContent="center"
              >
                <Typography variant="h2" className={classes.sectionEtapeTitle} >
                  <span color='#fff'>Nous travaillons ensemble<br/>en 4 étapes :</span>
                </Typography>

                <Typography
                  className={classnames(
                    'textParagraphe',
                    classes.sectionEtapeDescription,
                    classes.cardText,
                  )}
                >
                  {/* Prenez une longueur d’avance, avec une disponibilité rapide,
                  des couts raisonnables, évitez même les couts de recrutement.
                  <br />
                  <br />
                  Pour une exploitation intelligente de vos données. Nos Data
                  Scientist vous accompagnent dans la création de modèles
                  prédictifs avancés ainsi que leur application en solution
                  métiers pour permettre et favoriser vos prises de décisions
                  business. */}
                </Typography>
              </Box>
              <Box className={classes.etapesRight}>
                <Box
                  className={classes.services}
                  display="flex"
                  flexWrap="wrap"
                  alignItems="flex-start"
                >
                  <BoxWithIcon
                    title="Vous nous envoyez vos attentes"
                    // title="Computer Vision"
                    paragraph=""
                  />
                  <BoxWithIcon
                    // title="Iot"                   
                     title="Nous nous convenons d'un premier échange"
                    paragraph=""
                  />
                  <BoxWithIcon
                    // title="Recommendations Systems"                 
                       title="Nous vous proposons nos data scientist"
                    paragraph=""
                  />
                  <BoxWithIcon
                    // title="NLP"                    
                    title="Nous démarrons votre projet en mode régie"
                    // paragraph=""
                  />
                </Box>
              </Box>


            </Box>
          </Layout>
      </Box>
      <Box className= {classes.etapesWrapper} bgcolor="#0E0C38" style={{paddingBottom:160}}>
            <Layout className={classes.servicesContainer}>
                <Typography className={classes.titleWhy}  >Pourquoi HaiRun?</Typography>
              <Box className={classes.etapesWrapperContainer}>
                <Typography component="p">
                En externalisant vos projets Data science et Business Intelligence (BI) chez nous, vous pouvez vous concentrer sur vos activités principales. 
                Vous prenez de l'avance et gagnez du temps grâce à notre disponibilité à un coût optimal. 
                Nos consultants vous accompagnent pour une consommation intelligente de vos données.
                Ils élaborent pour vous des modèles prédictifs ou des dashboard avancés, et les intègrent dans vos processus métier.
                </Typography>
              </Box>
            </Layout>
          </Box>
          <Box className={classes.etapesWrapper}  bgcolor="#fff" style={{color:"#0E0C38",margin:'70px 0 120px'}}>
          <Layout className={classes.servicesContainer}>
            <Box >
              <Typography className={classes.sectionTechTitle}>
                Quelque technologie que nous maitrisons:
              </Typography>
              <Box>
                <ListeExpertise />
              </Box>
            </Box>
          </Layout>
      </Box>

      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </Box>
  );
};

export default DataScientist;