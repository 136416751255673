import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconNltk = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_6189"
        data-name="Groupe 6189"
        transform="translate(-79.171 -140.438)"
      >
        <path
          id="Tracé_16787"
          data-name="Tracé 16787"
          d="M79.171,163.338v-22.9H82.4l7.34,11.586a64.933,64.933,0,0,1,4.111,7.441l.068-.034c-.272-3.058-.339-5.844-.339-9.412v-9.581h2.786v22.9h-2.99L86.1,151.719a72.747,72.747,0,0,1-4.28-7.645l-.1.034c.17,2.889.238,5.64.238,9.446v9.785Z"
          transform="translate(0)"
          fill="#fff"
        />
        <path
          id="Tracé_16788"
          data-name="Tracé 16788"
          d="M108.149,140.438H111.1v20.419h9.785v2.48H108.149Z"
          transform="translate(-6.62)"
          fill="#fff"
        />
        <path
          id="Tracé_16789"
          data-name="Tracé 16789"
          d="M128.635,142.952H121.67v-2.514h16.954v2.514h-7v20.385h-2.99Z"
          transform="translate(-9.71)"
          fill="#fff"
        />
        <path
          id="Tracé_16790"
          data-name="Tracé 16790"
          d="M146.949,140.438H149.9V151.48h.1c.611-.883,1.223-1.7,1.8-2.446l7-8.6h3.669l-8.29,9.717,8.936,13.182h-3.5l-7.543-11.246-2.175,2.514v8.731h-2.956Z"
          transform="translate(-15.485)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};

export default IconNltk;
