import { makeStyles } from '@material-ui/core';
import Bg from '../../../images/datascientist/element_datascientist.svg';

export default makeStyles((theme) => ({
  cardOfferSection: {
    position: 'relative',
    '& .technologiesIconAws': {
      maxWidth: 90,
      width: 90,
    },
    '& .technologiesIconSmartpredict': {
      maxWidth: 225,
      width: 225,
      margin: '24px 28px',
    },
    '& .technologiesIconScikitlearn': {
      width: 80,
      margin: '24px 28px',
    },
    '& .technologiesIconJupyter': {
      width: 190,
      margin: '24px 28px',
    },

    '& .technologiesIconNltk': {
      width: 64,
      margin: '24px 28px',
    },
    '& .technologiesIconTensorFlow': {
      width: 275,
      margin: '24px 28px',
    },
    '& .technologiesIconKeras': {
      width: 167,
      margin: '24px 28px',
    },
    '& .MuiList-root': {
      justifyContent: 'center',
    },
  },
  confiancesTitle: {
    maxWidth: 1500,
    color: '#272727',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:after': {
      backgroundColor: '#D34246',
    },
  },
  cardOffersContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(9),
  },
  cardOffersTitle: {
    '&:after': {
      backgroundColor: '#D34246',
      right: 0,
      left: 'inherit',
    },
    '@media (max-width: 768px)': {
      textAlign: 'left !important',
      '&:after': {
        backgroundColor: '#D34246',
        right: 'inherit',
        left: 0,
      },
    },
  },
  cardOfferWrapper: {
    maxWidth: 1600,
    margin: '0 auto',
  },
  cardRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(15),
    '@media (max-width: 1200px)': {
      paddingTop: 20,
    },
    '@media (max-width: 768px)': {
      paddingTop: 0,
      flexWrap: 'wrap',
    },
  },
  cardOffer: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {},
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    width: '50%',
    // padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    '@media (max-width: 1200px)': {
      paddingTop: 10,
      paddingLeft: 0,
      paddingBottom: 10,
    },
    '@media (max-width: 992px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      order: '2 !important',
    },
    '&:hover>div:last-of-type': {
      opacity: 1,
    },
  },
  cardMedia: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',
    backgroundSize: 'contain',
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  cardMedia1: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',
    backgroundSize: 'contain',

    '@media (max-width: 1280px)': {
      flexBasis: '40%',
    },
    '@media (max-width: 1200px)': {
      flexBasis: '45%',
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  cardContent: {
    padding: 0,
  },
  cardTitle: {
    paddingLeft: 0,
    '@media (max-width: 992px)': {
      paddingRight: 0,
      width: '100%',
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      margin: 0,
      '& span': {},
      '& > .MuiCardHeader-content': {
        maxWidth: 'fit-content',
      },
    },
    '@media (max-width: 576px)': {},
    '& > .MuiCardHeader-avatar': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 77,
      height: 77,
      border: '1px solid #27272729',
      borderRadius: '50%',
      '@media (min-width: 769px) and (max-width: 991px)': {
        width: 44,
        height: 44,
      },
      '@media (max-width: 576px)': {
        width: 44,
        height: 44,
      },
      '& svg': {
        fontSize: '3rem',
        '@media (min-width: 769px) and (max-width: 991px)': {
          fontSize: '2rem',
        },
        '@media (max-width: 576px)': {
          fontSize: '2rem',
        },
      },
    },
    '& span': {
      lineHeight: 1.2,
      fontWeight: 400,
      textTransform: 'initial',
      fontFamily: 'ubuntu',
      fontSize: '2.125rem',
      '@media (min-width: 1200px) and (max-width: 1600px)': {
        fontSize: '2rem',
      },
      '@media (min-width: 992px) and (max-width: 1199px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width: 769px) and (max-width: 991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '1.5rem',
        textAlign: 'center',
      },
      '& span': {
        fontWeight: 600,
      },
      '& + span': {
        fontWeight: 300,
        fontSize: '1.25rem',
        marginTop: 20,
      },
      '& br': {
        '@media (max-width: 768px)': {
          display: 'none',
        },
      },
    },
  },
  cardTitleTop: {
    '& span': {
      fontSize: '6.5rem',
      fontWeight: 600,
      '@media (max-width: 1599px)': {
        fontSize: '6rem',
      },
      '@media (max-width: 1444px)': {
        fontSize: '5.5rem',
      },
      '@media (max-width: 1200px)': {
        fontSize: '4.5rem',
      },
      '@media (max-width: 600px)': {
        fontSize: '3rem',
      },
      '@media (max-width: 365px)': {
        fontSize: '2.5rem',
      },
    },
  },
  cardText: {
    color: '#808080',
    marginBottom: theme.spacing(2),
    '@media (max-width: 992px)': {
      fontSize: '1.125rem',
      marginBottom: 5,
    },
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      textAlign: 'center',
    },
    '@media (max-width: 576px)': {},
    '& span': {
      color: '#E0363A',
      fontWeight: 600,
    },
  },
  divider: {
    width: 30,
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#fff',
    backgroundColor: 'currentColor',
  },
  rollover: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& > div': {
      textAlign: 'center',
    },
  },
  btnDemarrer: {
    fontWeight: 400,
    fontSize: '0.875rem',
    borderRadius: 4,
    borderColor: '#E0363A',
    color: '#E0363A',
    boxShadow: 'none',
    transition: '50ms ease-in',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media (max-width: 360px)': {
      fontSize: '0.725rem',
    },
    '&:hover': {
      paddingLeft: 20,
      paddingRight: 20,
      transition: '50ms ease-in',
    },
    '& svg': {
      transition: '50ms ease-in',
    },
    '&:hover svg': {
      transition: '50ms ease-in',
      transform: 'translateX(5px)',
    },
  },
  externaliset: {
    color: '#fff',
    paddingBottom: 60,
    paddingTop: 60,
    '& p': {
      fontFamily: 'ubuntu',
    },
  },
  containerExternalise: {
    display: 'flex',
    maxWidth: 1700,
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
  titleWrapperExternalise: {
    width: '62%',
    maxWidth: 844,
    '& > div': {
      maxWidth: 844,
      '@media (max-width: 1024px)': {
        maxWidth: '100%',
      },
    },
    '& p': {
      fontSize: '1.250rem',
      maxWidth: 582,
      textAlign: 'right',
      margin: '0 0 0 auto',
      '@media (max-width: 1024px)': {
        fontSize: '1.125rem',
        margin: '0',
        textAlign: 'left',
        maxWidth: 1024,
      },
      '@media (max-width: 768px)': {
        margin: '0 auto',
        textAlign: 'center',
      },
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  titleExternalise: {
    '& ul':{
      padding:"unset"
    },
      '& ul li':{
      // listStyle:'none',
      // textAlign:'left',
    },
    textAlign: 'right',
    '&:after': {
      backgroundColor: '#D34246',
      left: 'inherit',
      right: 0,
      '@media (max-width: 1024px)': {
        left: 0,
        right: 'inherit',
      },
      '@media (max-width: 768px)': {
        left: 0,
        right: 0,
        margin: '0 auto',
      },
    },
    '@media (max-width: 1024px)': {
      textAlign: 'left',
    },
    '@media (max-width: 768px)': {
      textAlign: 'center',
    },
  },
  descriptionExternalise: {},
  BgExtenalise: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '0',
    backgroundPositionY: '0',
  },
  contentExternalise: {
    width: '38%',
    fontSize: '1rem',

    '& img': {
      width: '100%',
    },
    '& > div': {
      maxWidth: 580,
      marginLeft: 'auto',
      marginRight: 'auto',
      '@media (max-width: 1024px)': {
        maxWidth: '100%',
      },
    },
    '& ul': {
      paddingLeft: 0,
      listStyleType: 'none',
    },
    '& li': {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'flex-start',
      '@media (max-width: 992px)': {
        padding: 5,
      },
    },
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  OtherOffersItemListIcon: {
    backgroundColor: '#EBB32A',
    color: '#fff',
    borderRadius: '50%',
    marginRight: 10,
    marginTop: 6,
    width: 20,
    height: 20,
    '@media (max-width: 1200px)': {
      backgroundColor: '#EBB32A',
    },
  },
  process: {
    color: '#808080',
    fontSize: '1rem',
    position: 'relative',
    '& ul': {
      paddingLeft: 0,
      listStyleType: 'none',
    },
    '& li': {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'flex-start',
      fontSize: '0.9375rem',
      '@media (max-width: 992px)': {
        padding: 5,
      },
    },
    '& svg': {
      marginTop: 0,
    },
  },
  containerProcess: {
    display: 'flex',
    maxWidth: 1600,
    alignItems: 'start',
    flexDirection: 'column',
    paddingTop: 200,
    // paddingBottom: 200,
    '@media (max-width: 1200px)': {
      paddingTop: 100,
      paddingBottom: 100,
    },
    '@media (max-width: 992px)': {
      flexDirection: 'column',
      paddingBottom: 80,
      paddingTop: 42,
    },
  },
  imgProcess: {
    maxWidth: 1340,
    margin: '0 auto',
    padding: '42px 0px 160px',
    display: 'block',
    '& img': {
      width: '100%',
      zIndex: 1,
    },
    '@media (max-width: 992px)': {
      padding: '24px 0 22px',
      display: 'none',
    },
  },
  imgProcessMobile: {
    maxWidth: 1340,
    margin: '0 auto',
    padding: '42px 24px 160px',
    display: 'none',
    '& img': {
      width: '100%',
      zIndex: 1,
    },
    '@media (max-width: 992px)': {
      padding: '24px 0 22px',
      display: 'block',
    },
  },

  titleProcess: {
    '& h2': {
      fontSize: '3rem !important',
      color: '#272727',
      lineHeight: '52px !important',
      '@media (max-width: 1200px)': {
        fontSize: '2.5rem !important',
        lineHeight: '46px !important',
      },
    },
    '@media (max-width: 992px)': {
      maxWidth: '100%',
    },
  },

  titleWrapperContainer: {
    maxWidth: 1664,
  },

  titleWrapper: {
    position: 'relative',
    paddingTop: 103,
    paddingBottom: 103,
    '@media (max-width: 600px)': {
      paddingTop: 64,
      paddingBottom: 42,
    },
  },

  titleService: {
    color: '#fff',
    '&:after': {
      backgroundColor: '#E0363A',
      right: 0,
    },
  },
  backgroundProcess: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    '& img': {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
    },
  },
  etapesWrapper: {
    paddingTop: 60,
    paddingBottom: 30,
    width: '100%',
    color:'#fff',
    zIndex: 1,
  },
  etapesWrapperContainer: {
    display: 'flex',
    '@media (max-width: 992px)': {
      flexWrap: 'wrap',
    },
  },
  etapesLeft: {
    width: 404,
    minWidth: '33%',
    paddingRight: 90,
    '@media (max-width: 992px)': {
      width: '100%',
      minWidth: '100%',
      paddingRight: 0,
      margin: '0 auto 50px',
    },
  },

  sectionEtapeTitle: {
    fontFamily: 'Josefin Sans',
    fontSize: '3rem',
    fontWeight: 400,
    '& span': {
      fontWeight: 800,
    },

    '@media (max-width: 1024px)': {
      fontSize: '2.375rem',
    },
    '@media (max-width: 768px)': {
      fontSize: '1.875rem',
      textAlign: 'center',
    },
  },
  sectionEtapeDescription: { color: '#ffffff' },
  services: {
    counterReset: 'counter',
    '& :before': {
      color: '#ffffff!important',
      position:"absolute",
      left:'-20px'
    },
  },
  servicesContainer: {
    maxWidth: 1580,
    margin: '0 auto',
  },
  etapesRight: {
    '& p': {
      position:'relative',
      fontFamily: 'Josefin Sans',
      paddingLeft:20,
    },
  },
  sectionTech:{
    '& h4':{
      fontSize:'1.6rem'
    },
    color:'#000 !important',
    '&>span':{
      color:"#000 !important"
    }
  },
  titleWhy:{
    fontSize:'2.6rem !important',
    marginBottom:10
  },
  sectionTechTitle:{
    color:"#333",
    fontSize:'2.3rem !important'
  }
}));
