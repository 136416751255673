import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconScikitlearn = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="g3" transform="translate(0 0)">
        <path
          id="path5"
          d="M252.9,262.743c11.322-11.321,13.2-27.794,4.2-36.794s-25.472-7.118-36.793,4.2-8.046,32.949-4.2,36.794C219.21,270.052,241.575,274.063,252.9,262.743Z"
          transform="translate(-181.791 -220.26)"
          fill="#f89939"
        />
        <path
          id="path7"
          d="M145.259,286.98c-6.568-6.568-16.126-7.661-21.349-2.439s-4.13,14.782,2.439,21.349,19.119,4.669,21.348,2.439C149.5,306.528,151.829,293.549,145.259,286.98Z"
          transform="translate(-120.609 -260.856)"
          fill="#3499cd"
        />
      </g>
      <g id="g9" transform="translate(38.709 24.267)">
        <g id="g11" transform="translate(0 0)">
          <path
            id="path13"
            d="M245.01,308.3a18.262,18.262,0,0,1-3.053,2.363,5,5,0,0,1-2.5.762,2.676,2.676,0,0,1-2.209-1.062,4.481,4.481,0,0,1-.838-2.856,22.4,22.4,0,0,1,1.165-6.452,40.969,40.969,0,0,1,2.829-6.94l3.252-1.2a.815.815,0,0,1,.231-.051c.247,0,.451.182.607.544a3.743,3.743,0,0,1,.238,1.467,11.311,11.311,0,0,1-1.2,5.057,22.837,22.837,0,0,1-3.764,5.224q-.1,1.332-.1,1.831a3.3,3.3,0,0,0,.409,1.767,1.237,1.237,0,0,0,1.088.653,2.758,2.758,0,0,0,1.473-.5,17.578,17.578,0,0,0,2.38-2.075V308.3Zm-4.9-5.005a15.622,15.622,0,0,0,2.638-4.052,9.711,9.711,0,0,0,1.011-3.861,1.76,1.76,0,0,0-.14-.761c-.094-.192-.214-.289-.359-.289q-.472,0-1.376,2.359A44.186,44.186,0,0,0,240.107,303.291Z"
            transform="translate(-236.407 -292.855)"
            fill="#010101"
          />
          <path
            id="path15"
            d="M271.57,323.06a15.7,15.7,0,0,1-2.919,2.363,5.518,5.518,0,0,1-2.855.762,3.723,3.723,0,0,1-2.811-1.113,4.061,4.061,0,0,1-1.068-2.933,7.35,7.35,0,0,1,1.883-4.91,5.451,5.451,0,0,1,4.173-2.2,2.833,2.833,0,0,1,1.908.615,2.022,2.022,0,0,1,.717,1.613q0,2.651-5.633,4.3.511,2.5,2.676,2.5a3.124,3.124,0,0,0,1.613-.454,14.031,14.031,0,0,0,2.318-2.017v1.471Zm-6.709-2.368q3.277-.923,3.278-3.406,0-1.229-.9-1.229-.845,0-1.613,1.287A6.451,6.451,0,0,0,264.861,320.692Z"
            transform="translate(-253.39 -307.619)"
            fill="#010101"
          />
          <path
            id="path17"
            d="M304.163,322.959a27.175,27.175,0,0,1-2.932,2.537,3.068,3.068,0,0,1-1.69.588q-2.023,0-1.908-3.572a13.261,13.261,0,0,1-2.363,2.7,3.528,3.528,0,0,1-2.234.87,2.327,2.327,0,0,1-1.914-1.056,4.21,4.21,0,0,1-.787-2.593,6.961,6.961,0,0,1,1.055-3.662,8.692,8.692,0,0,1,2.708-2.816,5.56,5.56,0,0,1,2.919-1.076,2.265,2.265,0,0,1,2.241,1.472l2.617-1.446h.718l-1.13,3.751a16.278,16.278,0,0,0-.871,3.88q0,1.1.781,1.1a1.748,1.748,0,0,0,1.1-.531q.6-.532,1.689-1.62v1.472Zm-9.372.705q1.28,0,2.414-2.182a8.84,8.84,0,0,0,1.133-4.028,1.765,1.765,0,0,0-.32-1.12,1.039,1.039,0,0,0-.858-.4q-1.281,0-2.426,2.177a8.709,8.709,0,0,0-1.147,4.007,1.756,1.756,0,0,0,.34,1.121A1.052,1.052,0,0,0,294.791,323.664Z"
            transform="translate(-272.309 -307.518)"
            fill="#010101"
          />
          <path
            id="path19"
            d="M340.681,323.01q-3.214,3.149-4.955,3.15a1.643,1.643,0,0,1-1.319-.658,2.506,2.506,0,0,1-.538-1.634q0-1.805,1.934-4.839a7.141,7.141,0,0,1-2.074.678,14.714,14.714,0,0,1-2.612,3.3h-.294v-1.151a16.8,16.8,0,0,0,1.805-2.228,1.558,1.558,0,0,1-1.1-1.446,4.124,4.124,0,0,1,.673-2.108,2.067,2.067,0,0,1,1.85-1.119.893.893,0,0,1,1,1.024,7.043,7.043,0,0,1-.577,2.3q2.125-.231,3.713-3.252l1.165-.051-1.191,3.278q-.742,2.074-.96,2.824a4.835,4.835,0,0,0-.218,1.325,1.358,1.358,0,0,0,.249.858.805.805,0,0,0,.673.321,1.469,1.469,0,0,0,.883-.313q.422-.316,1.9-1.736v1.472Z"
            transform="translate(-299.262 -307.57)"
            fill="#010101"
          />
          <path
            id="path21"
            d="M372.631,322.96q-2.957,3.126-5.07,3.125a1.746,1.746,0,0,1-1.383-.6,2.362,2.362,0,0,1-.525-1.613,12.786,12.786,0,0,1,1.128-4.186,7.57,7.57,0,0,0,.6-1.921c0-.273-.108-.41-.32-.41a1.006,1.006,0,0,0-.474.179,5.829,5.829,0,0,0-.628.5q-.319.294-.717.716-.346.362-.742.82l-.718.833a2.642,2.642,0,0,0-.588,1.216,19.364,19.364,0,0,0-.257,2q-.039.677-.038,1.831l-2.83.665q-.14-1.728-.141-2.573a15.418,15.418,0,0,1,.481-3.905,25.259,25.259,0,0,1,1.543-4.149l3.123-.6a29.8,29.8,0,0,0-1.293,4.173,24.656,24.656,0,0,1,3.332-3.246,3.874,3.874,0,0,1,2.192-.9,1.389,1.389,0,0,1,1.086.492,1.794,1.794,0,0,1,.435,1.233,13.1,13.1,0,0,1-1.1,4.046,9.49,9.49,0,0,0-.756,2.51q0,.771.629.771.936,0,3.03-2.471Z"
            transform="translate(-318.638 -307.519)"
            fill="#010101"
          />
        </g>
      </g>
      <text
        id="text25"
        transform="translate(51.256 26.373)"
        fill="#fff"
        font-size="7"
        font-family="ArialMT, Arial"
      >
        <tspan x="0" y="0">
          scikit
        </tspan>
      </text>
    </SvgIcon>
  );
};

export default IconScikitlearn;
