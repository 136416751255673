import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconJupyter = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="Canvas" transform="translate(-1638.36 -2093.31)">
        <g id="logo" transform="translate(1638.36 2093.31)">
          <g id="Group" transform="translate(51.487 6.86)">
            <g id="g">
              <g id="path" transform="translate(0 6.31)">
                <g id="path0_fill" data-name="path0 fill">
                  <path
                    id="Tracé_16734"
                    data-name="Tracé 16734"
                    d="M5.735,18.274c0,5.246-.4,6.958-1.467,8.223A6.265,6.265,0,0,1,0,28.165l.4,3.12A9.82,9.82,0,0,0,7.059,28.8c1.912-1.991,2.578-4.743,2.578-8.967V0H5.726V18.3Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <g
                id="path-2"
                data-name="path"
                transform="translate(16.829 6.269)"
              >
                <g id="path1_fill" data-name="path1 fill">
                  <path
                    id="Tracé_16735"
                    data-name="Tracé 16735"
                    d="M18.084,15.925c0,2.26,0,4.25.178,5.963H14.839L14.617,18.3h-.089a8.115,8.115,0,0,1-3.01,3,8.027,8.027,0,0,1-4.1,1.057C4.037,22.363,0,20.462,0,12.787V0H3.912V12.132c0,4.161,1.245,6.958,4.792,6.958a5.621,5.621,0,0,0,3.111-1.01A5.7,5.7,0,0,0,13.852,15.5a5.9,5.9,0,0,0,.356-2.036V.018H18.12V15.925Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <g
                id="path-3"
                data-name="path"
                transform="translate(42.109 5.739)"
              >
                <g id="path2_fill" data-name="path2 fill">
                  <path
                    id="Tracé_16736"
                    data-name="Tracé 16736"
                    d="M.178,7.682C.178,4.884.089,2.625,0,.509H3.512L3.69,4.257h.089a8.472,8.472,0,0,1,3.232-3.2A8.38,8.38,0,0,1,11.416.006c5.192,0,9.1,4.483,9.1,11.119,0,7.864-4.7,11.746-9.78,11.746a7.47,7.47,0,0,1-3.758-.835,7.545,7.545,0,0,1-2.857-2.6H4.037V31.318H.169V7.646ZM4.045,13.51a9.041,9.041,0,0,0,.178,1.623,6.094,6.094,0,0,0,2.132,3.348,6,6,0,0,0,3.727,1.305c4.134,0,6.526-3.434,6.526-8.447,0-4.385-2.267-8.133-6.393-8.133A6.193,6.193,0,0,0,6.424,4.644,6.286,6.286,0,0,0,4.312,8.13a6.551,6.551,0,0,0-.267,1.623V13.51Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <g
                id="path-4"
                data-name="path"
                transform="translate(65.309 6.289)"
              >
                <g id="path3_fill" data-name="path3 fill">
                  <path
                    id="Tracé_16737"
                    data-name="Tracé 16737"
                    d="M4.241,0l4.7,12.921c.489,1.444,1.022,3.165,1.378,4.483h.089c.4-1.309.845-2.986,1.378-4.564L16.057.009h4.134L14.332,15.6c-2.8,7.5-4.7,11.343-7.371,13.692a10.534,10.534,0,0,1-4.792,2.573l-.978-3.345a10.292,10.292,0,0,0,3.423-1.946,12.034,12.034,0,0,0,3.29-4.43,2.887,2.887,0,0,0,.311-.95,3.512,3.512,0,0,0-.267-1.04L0,.009H4.268Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <g id="path-5" data-name="path" transform="translate(88.611)">
                <g id="path4_fill" data-name="path4 fill">
                  <path
                    id="Tracé_16738"
                    data-name="Tracé 16738"
                    d="M7.157,0V6.277H12.75V9.307H7.157V21.09c0,2.69.756,4.25,2.934,4.25a8.559,8.559,0,0,0,2.267-.269l.178,2.986a9.413,9.413,0,0,1-3.467.538,5.364,5.364,0,0,1-4.223-1.668c-1.111-1.175-1.511-3.12-1.511-5.694V9.307H0V6.277H3.334V1.031Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <g
                id="path-6"
                data-name="path"
                transform="translate(104.869 5.78)"
              >
                <g id="path5_fill" data-name="path5 fill">
                  <path
                    id="Tracé_16739"
                    data-name="Tracé 16739"
                    d="M3.7,12.159c.089,5.38,3.467,7.595,7.371,7.595a13.918,13.918,0,0,0,5.948-1.13l.667,2.843a17.149,17.149,0,0,1-7.113,1.354C3.956,22.82,0,18.391,0,11.791S3.823,0,10.082,0c7.015,0,8.891,6.277,8.891,10.3a15.236,15.236,0,0,1-.133,1.856H3.7Zm11.46-2.842c.044-2.529-1.022-6.465-5.415-6.465-3.956,0-5.681,3.7-5.993,6.465H15.159Z"
                    transform="translate(0)"
                    fill="#fff"
                  />
                </g>
              </g>
              <g
                id="path-7"
                data-name="path"
                transform="translate(129.353 5.79)"
              >
                <g id="path6_fill" data-name="path6 fill">
                  <path
                    id="Tracé_16740"
                    data-name="Tracé 16740"
                    d="M.178,7.317C.178,4.744.133,2.529,0,.494H3.423l.133,4.3h.178C4.712,1.848,7.068,0,9.682,0a4.154,4.154,0,0,1,1.111.135V3.883A5.793,5.793,0,0,0,9.46,3.749c-2.756,0-4.7,2.125-5.237,5.1a11.356,11.356,0,0,0-.178,1.856V22.364H.178Z"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="g-2" data-name="g">
            <g id="path-8" data-name="path" transform="translate(31.538)">
              <g id="path7_fill" data-name="path7 fill">
                <path
                  id="Tracé_16741"
                  data-name="Tracé 16741"
                  d="M6.007,2.9a3.051,3.051,0,0,1-.434,1.7,3.014,3.014,0,0,1-1.3,1.174,2.986,2.986,0,0,1-3.3-.514A3.046,3.046,0,0,1,1.231.585,2.986,2.986,0,0,1,5.037.8,3.042,3.042,0,0,1,6.007,2.9Z"
                  transform="translate(0 0)"
                  fill="#767677"
                />
              </g>
            </g>
            <g id="path-9" data-name="path" transform="translate(1.407 31.263)">
              <g id="path8_fill" data-name="path8 fill">
                <path
                  id="Tracé_16742"
                  data-name="Tracé 16742"
                  d="M18.618,7.272C10.616,7.272,3.628,4.34,0,0A19.882,19.882,0,0,0,7.205,9.656a19.616,19.616,0,0,0,11.417,3.67,19.616,19.616,0,0,0,11.417-3.67A19.882,19.882,0,0,0,37.244,0C33.608,4.34,26.62,7.272,18.618,7.272Z"
                  fill="#f37726"
                />
              </g>
            </g>
            <g id="path-10" data-name="path" transform="translate(1.396 4.251)">
              <g id="path9_fill" data-name="path9 fill">
                <path
                  id="Tracé_16743"
                  data-name="Tracé 16743"
                  d="M18.627,6.054c8,0,14.99,2.932,18.618,7.272A19.882,19.882,0,0,0,30.039,3.67a19.593,19.593,0,0,0-22.834,0A19.882,19.882,0,0,0,0,13.326C3.636,9,10.625,6.054,18.627,6.054Z"
                  fill="#f37726"
                />
              </g>
            </g>
            <g
              id="path-11"
              data-name="path"
              transform="translate(1.468 43.322)"
            >
              <g id="path10_fill" data-name="path10 fill">
                <path
                  id="Tracé_16744"
                  data-name="Tracé 16744"
                  d="M7.571,3.653A3.845,3.845,0,0,1,7.025,5.8,3.8,3.8,0,0,1,5.386,7.283a3.763,3.763,0,0,1-4.161-.65,3.839,3.839,0,0,1,.33-5.9,3.763,3.763,0,0,1,4.795.273A3.833,3.833,0,0,1,7.571,3.653Z"
                  transform="translate(0 0)"
                  fill="#989798"
                />
              </g>
            </g>
            <g id="path-12" data-name="path" transform="translate(0 4.842)">
              <g id="path11_fill" data-name="path11 fill">
                <path
                  id="Tracé_16745"
                  data-name="Tracé 16745"
                  d="M2.319,4.481a2.208,2.208,0,0,1-1.25-.324,2.235,2.235,0,0,1-.861-.97A2.256,2.256,0,0,1,.586.725a2.212,2.212,0,0,1,3.432.2,2.256,2.256,0,0,1-.159,2.838,2.215,2.215,0,0,1-1.54.723Z"
                  transform="translate(0 0)"
                  fill="#6f7070"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default IconJupyter;
