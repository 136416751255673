import React from 'react';

import classnames from 'classnames';

import styles from './style';

import ReactHtmlParser from 'react-html-parser';

import { Box, Typography, ListItem, ListItemIcon } from '@material-ui/core';
import Layout from '../../../Common/Layout';
import SlickSlider from '../../../Common/SlickSlider';
import { PrevArrow, NextArrow } from '../../../Common/SlickSlider/Arrow';

// import Amazon from './LogoTechno/amazon-web-services.svg';
import Amazon from './LogoTechno/amazon-web-services.png';
import Apache_airflow from './LogoTechno/Apache_airflow.png';
import apache_http_server from './LogoTechno/apache-http-server.png';
import apache_kafka from './LogoTechno/apache-kafka.png';
import apacheSpark from './LogoTechno/apache-spark.png';
import dash_plotlylogo from './LogoTechno/dash+plotlylogo.png';
import Google_Data_Studio from './LogoTechno/Google-Data-Studio.png';
import grafana from './LogoTechno/grafana.png';
import knowage from './LogoTechno/knowage.png';
import Airflow from './LogoTechno/Airflow.png';
import Power_BI_Logo from './LogoTechno/Power_BI_Logo.png';
import pytorchDeepLearning from './LogoTechno/pytorch-deep-learning.png';
import scikitLearnPython from './LogoTechno/scikitLearnPython.png';
import Tableau from './LogoTechno/Tableau.png';
import Talend from './LogoTechno/Talend.png';
import tensorflow_Google_Brain from './LogoTechno/tensorflow_Google_Brain.png';

const ConfiancesData = [
  {
    id: 1,
    title: 'Amazon',
    image: Amazon,
  },
  {
    id: 2,
    title: 'Apache_airflow',
    image: Apache_airflow,
  },
  {
    id: 3,
    title: 'apache_http_server',
    image: apache_http_server,
  },
  {
    id: 4,
    title: 'apache_kafka',
    image: apache_kafka,
  },
  {
    id: 5,
    title: 'apacheSpark',
    image: apacheSpark,
  },
  {
    id: 6,
    title: 'dash_plotlylogo',
    image: dash_plotlylogo,
  },
  {
    id: 7,
    title: 'Google_Data_Studio',
    image: Google_Data_Studio,
  },
  {
    id: 8,
    title: 'grafana',
    image: grafana,
  },
  {
    id: 9,
    title: 'knowage',
    image: knowage,
  },
  {
    id: 10,
    title: 'Airflow',
    image: Airflow,
  },
  {
    id: 11,
    title: 'Power_BI_Logo',
    image: Power_BI_Logo,
  },
  {
    id: 12,
    title: 'pytorch-deep-learning',
    image: pytorchDeepLearning,
  },
  {
    id: 13,
    title: 'scikitLearnPython',
    image: scikitLearnPython,
  },
  {
    id: 14,
    title: 'Tableau',
    image: Tableau,
  },
  {
    id: 15,
    title: 'Talend',
    image: Talend,
  },
  {
    id: 16,
    title: 'tensorflow_Google_Brain',
    image: tensorflow_Google_Brain,
  },

];

interface PropTypes {
  title: string;
}
const Confiances = ({ title }: PropTypes) => {
  const classes = styles();

  const slickSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    // autoplaySpeed: 3000,
    arrows: false,
    prevArrow: (
      <PrevArrow
        classes={classnames(
          classes.confiancesSlickArrow,
          classes.confiancesSlickPrev,
        )}
      />
    ),
    nextArrow: (
      <NextArrow
        classes={classnames(
          classes.confiancesSlickArrow,
          classes.confiancesSlickNext,
        )}
      />
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box id="bloc-confiance" className={classes.confiancesWrapper}>
      <Box className={classes.background} />
      <Layout>
        <Box>
          {/* <Typography
            variant="h2"
            className={classnames('titreSection', classes.confiancesTitle)}
          >
            {ReactHtmlParser(title)}
          </Typography> */}
          <SlickSlider
            settings={slickSettings}
            classesnames={classes.confiancesList}
          >
            {ConfiancesData.map(({ id, image, title }) => (
              <ListItem key={id} className={classes.confiancesListItem}>
                <ListItemIcon className={classes.confiancesListItemIcon}>
                  <img src={image} alt={title} />
                </ListItemIcon>
                {/* <p>{title}</p> */}
              </ListItem>
            ))}
          </SlickSlider>
        </Box>
      </Layout>
    </Box>
  );
};

export default Confiances;
