import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconKeras = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_6190"
        data-name="Groupe 6190"
        transform="translate(4458 16792.346)"
      >
        <g id="Keras_logo" transform="translate(-4458 -16792.346)">
          <g id="Groupe_6160" data-name="Groupe 6160">
            <path
              id="Tracé_16752"
              data-name="Tracé 16752"
              d="M50.961,50.959l0,0H0l0,0V0L0,0H50.96l0,0V50.959Z"
              fill="#fff"
            />
          </g>
          <g
            id="Groupe_6161"
            data-name="Groupe 6161"
            transform="translate(0.849 0.849)"
          >
            <path
              id="Tracé_16753"
              data-name="Tracé 16753"
              d="M67.262,67.26l0,0H18l0,0V18l0,0H67.261l0,0V67.26Z"
              transform="translate(-18 -18)"
              fill="#d00000"
            />
          </g>
          <g
            id="Groupe_6162"
            data-name="Groupe 6162"
            transform="translate(14.297 10.724)"
          >
            <path
              id="Tracé_16754"
              data-name="Tracé 16754"
              d="M302.99,255.4a.188.188,0,0,0,.048.115l.843.843a.188.188,0,0,0,.115.048h2.885a.188.188,0,0,0,.115-.048l.843-.843a.188.188,0,0,0,.048-.115v-9.156a.181.181,0,0,1,.049-.114l3.666-3.5a.055.055,0,0,1,.087.009l9.262,13.712a.143.143,0,0,0,.105.056h4.088a.122.122,0,0,0,.1-.06l.742-1.437a.124.124,0,0,0-.008-.116l-10.834-15.7a.084.084,0,0,1,.009-.1l9.965-9.918a.187.187,0,0,0,.048-.115v-.183a.4.4,0,0,0-.027-.13l-.575-1.322a.112.112,0,0,0-.094-.062h-4.041a.188.188,0,0,0-.115.048l-12.377,12.424c-.026.026-.048.017-.048-.02v-11.4a.2.2,0,0,0-.046-.117l-.827-.887a.178.178,0,0,0-.114-.05H304a.178.178,0,0,0-.114.05l-.847.914a.2.2,0,0,0-.046.117V255.4Z"
              transform="translate(-302.99 -227.26)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Tracé_16782"
          data-name="Tracé 16782"
          d="M759.858,230.364c4.315-4.281,8.48-8.253,12.438-12.421,1.574-1.657,3.359-1.175,5.062-1.054,1.031.073.547.932.006,1.466-3,2.968-5.937,6-9,8.891-1,.947-1,1.56-.24,2.637,3.326,4.689,6.569,9.436,9.814,14.182.317.464,1.051.861.6,1.612-.374.625-1.031.282-1.559.388-2.01.4-3.171-.5-4.232-2.2-2.443-3.905-5.134-7.655-7.681-11.5-.51-.769-.707-.918-1.576-.242-2.874,2.236-4.328,4.919-3.664,8.64.207,1.16-.073,2.4.057,3.583.2,1.819-.93,1.692-2.143,1.731-1.285.041-1.983-.16-1.97-1.746q.108-12.913-.006-25.826c-.019-1.8.916-1.719,2.146-1.742,1.255-.024,2.031.112,1.985,1.727C759.783,222.358,759.858,226.225,759.858,230.364Z"
          transform="translate(-5148.46 -16998.26)"
          fill="#fff"
        />
        <path
          id="Tracé_16783"
          data-name="Tracé 16783"
          d="M836.843,251.695c1.882.034,3.764.01,5.649.01h3.593c3.791,0,3.791,0,3.449-3.867-.36-4.028-2.948-7.158-6.486-7.836-4.487-.86-8.2.915-10.116,4.816a14.018,14.018,0,0,0-1.2,7.329c.579,7.943,9.924,12.533,16.533,8.1,1.094-.734.586-2.047.415-2.972-.2-1.07-1.152-.442-1.82-.226a15.4,15.4,0,0,1-5.378.826,5.5,5.5,0,0,1-5.533-5.214C835.856,251.846,836.113,251.681,836.843,251.695Zm-.476-4.991a4.626,4.626,0,0,1,5.279-3.274,4.087,4.087,0,0,1,3.613,3.709c.13.771.027,1.159-.888,1.111-1.244-.069-2.5-.017-3.743-.017-1.08,0-2.163-.079-3.233.021C835.965,248.383,835.948,247.821,836.366,246.7Z"
          transform="translate(-5198.354 -17013.414)"
          fill="#fff"
        />
        <path
          id="Tracé_16784"
          data-name="Tracé 16784"
          d="M961.034,245.437a5.147,5.147,0,0,0-4.23-4.967,14.119,14.119,0,0,0-9.8,1.183c-.284.137-.617.243-.62.7a2.123,2.123,0,0,0,2.914,2.136c.638-.243,1.289-.446,1.944-.641a7.819,7.819,0,0,1,3.39-.189,3.271,3.271,0,0,1,2.592,3.994c-.254,1.642-1.656.689-2.478.782-2.379.264-4.779.336-7,1.378-2.653,1.244-3.833,3.455-3.51,6.565a5.559,5.559,0,0,0,4.731,5.111,8.7,8.7,0,0,0,8.553-2.386c.171.7.284,1.131.384,1.567.319,1.364,1.4.552,2.119.7s1.2-.243,1.193-1.059C961.175,255.351,961.25,250.387,961.034,245.437Zm-3.918,8.189a5.037,5.037,0,0,1-5.828,4.631c-1.453-.144-2.667-.662-2.893-2.352a3.057,3.057,0,0,1,2.026-3.524,5.84,5.84,0,0,1,1.3-.391c1.183-.171,2.369-.288,3.555-.428C957.363,251.508,957.373,251.522,957.116,253.627Z"
          transform="translate(-5272.294 -17013.596)"
          fill="#fff"
        />
        <path
          id="Tracé_16785"
          data-name="Tracé 16785"
          d="M1016.094,261.566a16.561,16.561,0,0,1-4.325-.527c-2.385-.629-2.512-.849-2.415-3.232.049-1.2.532-1.35,1.517-.934a11.987,11.987,0,0,0,6.989,1.041c1.228-.23,2.4-.581,2.667-2,.28-1.475-.769-2.19-1.877-2.712-1.852-.87-3.778-1.584-5.623-2.467-2.914-1.4-4.047-3.356-3.647-6.077.33-2.242,2.359-4.079,5.127-4.608a15.793,15.793,0,0,1,8.411.761c.718.257,1.56.646.833,1.562-.583.735.045,2.856-2.114,1.945a11.575,11.575,0,0,0-6.028-.844c-1.051.133-2.1.456-2.352,1.643-.262,1.234.65,1.9,1.6,2.334,1.862.849,3.793,1.546,5.657,2.391,1.928.874,3.553,2.071,3.9,4.382.5,3.306-1.158,5.8-4.54,6.813A12.189,12.189,0,0,1,1016.094,261.566Z"
          transform="translate(-5315.088 -17013.41)"
          fill="#fff"
        />
        <path
          id="Tracé_16786"
          data-name="Tracé 16786"
          d="M903.746,242.916a7.626,7.626,0,0,1,7.578-3.032c.958.15,1.232.407,1.083,1.373-.362,2.347-.32,2.353-2.749,2.25a5.448,5.448,0,0,0-5.469,4.867c-.5,3.623-.184,7.282-.167,10.924.007,1.508-.434,1.991-1.939,1.944-1.375-.043-2.223-.105-2.19-1.893.107-5.7.042-11.395.037-17.092,0-1.037-.2-2.043,1.454-2.074,1.485-.028,2.46.152,2.2,1.942A3.658,3.658,0,0,0,903.746,242.916Z"
          transform="translate(-5243.182 -17013.398)"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};

export default IconKeras;
