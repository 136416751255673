import React, { ReactNode } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

interface IBoxWithIcon {
  icon?: ReactNode;
  title: string;
  paragraph: string;
}

const styles = makeStyles((theme) => ({
  boxWithIcon: {
    width: '50%',

    flexDirection: 'column',
    borderLeft: '1px solid #CCCCCC',
    paddingLeft: 50,
    paddingRight: 50,
    //'&:before': {
    //   color: '#808080',
    //  display: 'inline-block',
    //content: '"0"counter(counter)"."',
    // },
    '@media (max-width: 768px)': {
      paddingLeft: 40,
      paddingRight: 40,
    },
    '@media (max-width: 600px)': {
      width: '100%',
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  icon: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    '& svg': {
      fontSize: '2rem',
    },
  },
  title: {
    counterIncrement: 'counter',
    fontFamily: 'Josefin Sans',
    fontSize: '1.925rem',
    marginTop: 15,
    '&:before': {
      color: '#808080',
      display: 'inline-block',
      content: 'counter(counter)"."',
      marginRight: 12,
      fontSize: '1.375rem',
    },
    '@media (max-width: 1024px)': {
      fontSize: '1.875rem',
    },
  },
  paragraph: {
    color: '#808080',
    lineHeight: '31px',
    '@media (max-width: 992px)': {
      fontSize: '1.125rem',
      marginBottom: 5,
    },
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      textAlign: 'left',
    },
    '@media (max-width: 576px)': {},
    '& span': {
      color: '#E0363A',
      fontWeight: 600,
    },
  },
}));

export default function BoxWithIcon(props: IBoxWithIcon) {
  const { icon, title, paragraph } = props;
  const classes = styles();
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      mb={7}
      className={classes.boxWithIcon}
    >
      <Box className={classes.icon}>{icon}</Box>
      <Box>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.paragraph}>{paragraph}</Typography>
      </Box>
    </Box>
  );
}
